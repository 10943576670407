<template>
    <div class="inner-section">
        <card>
            <template v-slot:searchHeaderTitle>
                <h4 class="card-title">{{$t('externalTraining.complain')}}</h4>
            </template>
            <template v-slot:searchBody>
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('externalTraining.complain_title')"
                        label-for="complain_title"
                        >
                        <b-form-input
                            id="complain_title"
                            v-model="search.complain_title"
                            placeholder=""
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" md="6" sm="12">
                        <b-button size="sm" variant="primary" @click="searchData">
                            <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                        </b-button>
                    </b-col>
                </b-row>
            </template>
        </card>
        <body-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('externalTraining.complain_list')}}</h4>
            </template>
            <template v-slot:headerAction>
                <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-4>
                    <i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}
                </a>
            </template>
            <template v-slot:body>
                <b-overlay :show="loadingState">
                    <select-column-check :labelData="labelData" :search="search" :columns="columns" />
                    <b-row>
                        <b-col md="12" class="table-responsive">
                            <b-table head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                                <template v-slot:cell(index)="data">
                                {{ $n(data.index + pagination.slOffset) }}
                                </template>
                                <template v-slot:cell(complain_date)="data">
                                    <span>{{ data.item.complain_date | dateFormat }}</span>
                                </template>
                                <template v-slot:cell(attachment)="data">
                                    <a  v-if="data.item.attachment" target = '_blank' :href="baseUrl + 'download-attachment?file=uploads/complain-external/' + data.item.attachment" title="Attachment" class="mr-3" download><i class="ri-download-cloud-fill"></i></a>
                                </template>
                                <template v-slot:cell(status)="data">
                                    <span class="badge badge-warning" v-if="data.item.status === 1">{{ $t('externalTraining.not_resolved') }}</span>
                                    <span class="badge badge-success" v-if="data.item.status === 2">{{ $t('externalTraining.resolved') }}</span>
                                    <!-- {{ v-if="data.item.status === 1" $t('externalTraining.not_resolved') }} -->
                                    <!-- {{ data.item.status ? $t('externalTraining.not_resolved') : $t('externalTraining.resolved') }} -->
                                </template>
                                <template v-slot:cell(action)="data">
                                    <b-button v-if="data.item.status === 1" title="Edit" v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                    <span v-if="data.item.status === 2">
                                        <b-button variant="iq-bg-success" size="sm" class="action-btn btn-success" v-b-modal.modal-details :title="$t('globalTrans.details')" @click="details(data.item)"><i class="ri-eye-line"></i></b-button>
                                    </span>
                                    <!-- <b-button title="Active/Inactive" variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button> -->
                                </template>
                            </b-table>
                            <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                                <b-pagination
                                    v-model="pagination.currentPage"
                                    :perPage="search.limit"
                                    :total-rows="pagination.totalRows"
                                    @input="searchData"
                                />
                            </div>
                        </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </body-card>
        <b-modal id="modal-4" size="lg" hide-footer :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-details" size="lg" :title="$t('globalTrans.details')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Details :item="itemData"/>
        </b-modal>
    </div>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { complainList, complainToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import Form from './Form'
import Details from './Details'
import { mapGetters } from 'vuex'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form, Details
    },
    data () {
        return {
            search: {
                complain_title: '',
                limit: 20
            },
            itemData: [],
            editItemId: 0,
            baseUrl: trainingElearningServiceBaseUrl,
            labelData: [
                { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
                { labels: 'externalTraining.complain_re_title', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
                { labels: 'globalTrans.date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '15%' } },
                { labels: 'externalTraining.description', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '15%' } },
                { labels: 'globalTrans.attachment', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '25%' } },
                { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '10%' } },
                { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 9, thStyle: { width: '10%' } }
            ]
        }
    },
    computed: {
      ...mapGetters({
          authUser: 'Auth/authUser'
      }),
        formTitle () {
            return (this.editItemId === 0) ? this.$t('externalTraining.complain') + ' ' + this.$t('globalTrans.entry') : this.$t('externalTraining.complain_modify')
        },
        columns () {
            const labelData = this.labelData
            const labels = labelData.map((item, index) => {
                return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
            })

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'complain_title_bn' },
                { key: 'complain_date' },
                { key: 'description_bn' },
                { key: 'attachment' },
                { key: 'status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'complain_title' },
                { key: 'complain_date' },
                { key: 'description' },
                { key: 'attachment' },
                { key: 'status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    watch: {
        'search.limit': function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.loadData()
        }
        }
    },
     mounted () {
    },
    methods: {
        details (item) {
            this.itemData = item
        },
        dataChange () {
            this.loadData()
        },
        resetId () {
            this.editItemId = 0
        },
        async searchData () {
            this.loadData()
        },
        remove (item) {
            this.changeStatus(trainingElearningServiceBaseUrl, complainToggleStatus, item)
        },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(trainingElearningServiceBaseUrl, complainList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data, this.search.limit)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        }
    }
}
</script>
