<template>
    <b-overlay :show="loading">
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" enctype="multipart/form-data">
                <b-row>
                    <b-col sm="6">
                        <ValidationProvider name="Complain Title (En)" vid="complain_title" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="complain_title"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('externalTraining.complain_title_en')}} <span class="text-danger">*</span>
                            </template>
                                <b-form-input
                                id="complain_title"
                                v-model="complainData.complain_title"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="6">
                        <ValidationProvider name="Complain Title (Bn)" vid="complain_title_bn" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="complain_title_bn"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('externalTraining.complain_title_bn')}} <span class="text-danger">*</span>
                            </template>
                                <b-form-input
                                id="complain_title_bn"
                                v-model="complainData.complain_title_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="6">
                        <ValidationProvider name="Complain Date" vid="complain_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="complain_date"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('externalTraining.complain_date')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input class="fromDate"
                                    v-model="complainData.complain_date"
                                    placeholder="Select Date"
                                    id="datepicker"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="6">
                        <ValidationProvider name="Attachment" vid="attachment">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="attachment"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{$t('committee.attachment')}}
                            </template>
                                <b-form-file
                                id="attachment"
                                v-on:change="onFileChange"
                                v-model="complainData.attachment"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-file>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                                <div class="invalid-feedback d-block text-muted">
                                    {{ $i18n.locale == 'en' ? '[Maximum file size is 2 MB and file format is JPG/JPEG/PDF]' : '[সর্বোচ্চ ফাইল সাইজ 2 MB এবং ফাইল ফরমেট JPG/JPEG/PDF]' }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="6">
                        <ValidationProvider name="Description (En)" vid="description" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="description"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('externalTraining.description_en')}} <span class="text-danger">*</span>
                            </template>
                                <b-form-textarea
                                rows="3"
                                id="description"
                                v-model="complainData.description"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="6">
                        <ValidationProvider name="Description (Bn)" vid="description_bn" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="description_bn"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('externalTraining.description_bn')}} <span class="text-danger">*</span>
                            </template>
                                <b-form-textarea
                                rows="3"
                                id="description_bn"
                                v-model="complainData.description_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="6">
                        <ValidationProvider name="Remarks" vid="remarks">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="remarks"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('globalTrans.remarks')}}
                            </template>
                                <b-form-textarea
                                rows="3"
                                id="remarks"
                                v-model="complainData.remarks"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>
                <div class="text-right">
                    <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                    <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                </div>
            </b-form>
        </ValidationObserver>
    </b-overlay>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { complainStore, complainUpdate, profileInfo } from '../../api/routes'
import flatpickr from 'flatpickr'
// import { mapGetters } from 'vuex'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getFormData()
            this.complainData = tmp
            // const user = this.authUser
        }
        RestApi.getData(trainingElearningServiceBaseUrl, `${profileInfo}`).then(response => {
            if (response.success) {
                this.complainData.org_id = response.data.professional_info.org_id
                this.complainData.complain_by = response.data.user_id
                this.complainData.designation_en = response.data.professional_info.designation_en
                this.complainData.designation_bn = response.data.professional_info.designation_bn
                this.complainData.mobile = response.data.mobile
            } else {
                this.complainData.org_id = ''
                this.complainData.complain_by = ''
                this.complainData.designation_en = ''
                this.complainData.designation_bn = ''
                this.complainData.mobile = ''
            }
        })
    },
    data () {
        return {
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            complainData: {
                org_id: '',
                complain_by: '',
                complain_title: '',
                complain_title_bn: '',
                designation_en: '',
                designation_bn: '',
                description: '',
                description_bn: '',
                complain_date: '',
                mobile: '',
                remarks: '',
                attachment: []
            },
            // org_id: 'hanif',
            // complain_by: '',
            // designation: '',
            loading: false,
            attachmentDemo: []
        }
    },
    computed: {
        // ...mapGetters({
        // authUser: 'Auth/authUser'
        // })
    },
    mounted () {
        flatpickr('.fromDate', {})
    },
    watch: {
    },
    methods: {
        getFormData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        onFileChange (e) {
            this.attachmentDemo = e.target.files[0]
        },
        async createData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadinState = { loading: false, listReload: false }
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }

            var formData = new FormData()
            Object.keys(this.complainData).map(key => {
                if (key === 'attachment') {
                formData.append(key, this.attachmentDemo)
                } else {
                formData.append(key, this.complainData[key])
                }
            })
            if (this.complainData.id) {
                formData.append('_method', 'PUT')
                result = await RestApi.postData(trainingElearningServiceBaseUrl, `${complainUpdate}/${this.id}`, formData, config)
            } else {
                result = await RestApi.postData(trainingElearningServiceBaseUrl, complainStore, formData, config)
            }

            loadinState.listReload = true

            this.$store.dispatch('mutateCommonProperties', loadinState)

            if (result.success) {
                this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                title: 'Success',
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
            this.loading = false
        }
    }
}
</script>
